import React from 'react';

const OverpaymentsPreferencesFAQ = () => ([
    {
      title: 'What are mortgage overpayments?',
      description: (
        <>
          A mortgage overpayment is an additional payment on top of your regular monthly payments set out in your mortgage terms. Usually, you can make 10% of the mortgage amount as an overpayment.
        </>
      ),
    },
    {
      title: 'What are the benefits of making an overpayment?',
      description: (
        <>
          The key benefit of making a mortgage overpayment is that you could save money and become mortgage-free, faster.<br/><br/>
          The benefit of making an overpayment is that you will be directly reducing the mortgage balance and retaining more equity in your property, which results in you taking less time to repay the whole mortgage debt.
        </>
      ),
    },
    {
      title: 'Why do I need to update my lender about making overpayments?',
      description: (
        <>
          Often, the lender’s default is to keep the term the same and reduce your monthly repayments, which won't have an impact on reducing your mortgage interest, so it's best to call and make sure your preferences are updated.
        </>
      ),
    },
    {
      title: 'Are there any potential drawbacks to mortgage overpayments?',
      description: (
        <>
          There are some things to take into consideration when making overpayments. Some mortgages might have early repayment charges or restrictions on overpayments, so it's important to check your mortgage terms before making extra payments.<br/><br/>
          It can be difficult to get the money back once you've made the payment to your lender. It's wise to have an emergency fund aside in case of any unforeseen circumstances so you have access to this before making overpayments on your mortgage either as a lump sum or as regular small payments.
        </>
      ),
    },
    {
      title: 'Can I make overpayments with Sprive?',
      description: (
        <>
          You can use Sprive to make easy one-tap overpayments straight to your lender. You can use Sprive's autosaving AI and we'll set aside spare cash for you and then you can put this towards your mortgage in one tap. Or you can make one-off mortgage overpayments when you like.<br/><br/>
          You can use our mortgage overpayment calculator to see how much you could save in interest and years saved off your mortgage term.
        </>
      ),
    },
  ]);

export default OverpaymentsPreferencesFAQ;
